import axios from "axios";
import { BASE_URL } from "@/service_urls";
import { getCustomerId } from "@/helpers/helper";

const customer = {
  getCandidatesList: ({ skip, limit, page, query, role, org_id, isTFWPool }, relationshipOrgDetail) => {
    // let url;
    // if (role === "system_admin") {
    //   url = `${BASE_URL}/candidate/card/detail/?skip=${skip}&limit=${limit}&order_by=-created_on&${query}`
    // } else if (org_id) {
    //   url = `${BASE_URL}/customer/${getCustomerId()}/candidate/card/detail/?organisation_id=${org_id}&skip=${skip}&limit=${limit}&order_by=-created_on&doh_candidate_status___customer_id__in__and=null&${query}`
    // } else if (role === "customer_admin" && relationshipOrgDetail?.length) {
    //   url = `${BASE_URL}/customer/${getCustomerId()}/candidate/agency_candidates?skip=${skip}&limit=${limit}&order_by=-created_on&doh_candidate_status___customer_id__in__and=null&${query}`
    // } else {
    //   url = `${BASE_URL}/customer/${getCustomerId()}/candidate/card/detail/?skip=${skip}&limit=${limit}&order_by=-created_on&doh_candidate_status___customer_id__in__and=null&${query}`
    // }
    let api = {
      base_api: `${BASE_URL}/customer/${getCustomerId()}/candidate/card/detail/`,
      static_query: `skip=${skip}&limit=${limit}&order_by=-created_on`,
      dynamic_query: `doh_candidate_status___customer_id__in__and=null`,
    };
    if (role === "system_admin") {
      api.base_api = `${BASE_URL}/candidate/card/detail/`;
      api.static_query = '';
    } else if (role === "customer_admin" && relationshipOrgDetail?.length) {
      api.base_api = `${BASE_URL}/customer/${getCustomerId()}/candidate/agency_candidates`
    }
    if (org_id)
      api.dynamic_query = `${api.dynamic_query}&organisation_id=${org_id}`
    if (isTFWPool) {
      api.base_api = `${BASE_URL}/customer/${getCustomerId()}/candidate/card/detail/super_customer/`
    }
    if (query)
      api.dynamic_query = `${api.dynamic_query}&${query}`
    const url = `${api.base_api}?${api.static_query}&${api.dynamic_query}`;
    if (role === "customer_admin" && relationshipOrgDetail?.length) {
      return axios({
        method: 'POST',
        url: url,
        data: relationshipOrgDetail
      });
    } else {
      return axios({
        method: 'GET',
        url: url
      });
    }
  },
  allJobs: payload => {
    return axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/?${payload}`);
  },
  allCandidates: payload => {
    return axios.get(
      `${BASE_URL}/customer/${getCustomerId()}/candidate/?order_by=-created_on&${payload}`
    );
  },
  allunshortlistCandidates: payload => {
    return axios.get(
      `${BASE_URL}/customer/${getCustomerId()}/candidate/?order_by=-created_on&${payload}`
    );
  },
  allShortlistStatus: () => {
    return axios.get(
      `${BASE_URL}/status/?status_type=DOH_CANDIDATE_PROCESS`);
  },
  saveshortlistCandidates: payload => {
    return axios.post(
      `${BASE_URL}/customer/${getCustomerId()}/candidate/shortlist/`, payload);
  },
  updateShortlistStatus: payload => {
    return axios.put(
      `${BASE_URL}/customer/${getCustomerId()}/candidate/shortlist/`, payload);
  },
  totalCandidates: ({payload, org_id, role, relationshipOrgDetail, query, isTFWPool}) => {
    // let url
    // if (role === "system_admin") {
    //   url = `${BASE_URL}/candidate/total_count`;
    // } else if (role === "customer_admin" && relationshipOrgDetail?.length) {
    //   url = `${BASE_URL}/customer/${getCustomerId()}/candidate/agency_candidates/total_count`
    // }
    // else if (org_id) {
    //   url = `${BASE_URL}/customer/${getCustomerId()}/organisation/${org_id}/candidate/total_count`
    // } else {
    //   url = `${BASE_URL}/customer/${getCustomerId()}/candidate/total_count`;
    // }

    let url = `${BASE_URL}/customer/${getCustomerId()}/candidate/total_count`;
    if (role === "system_admin") {
      url = `${BASE_URL}/candidate/total_count`;
    } else if (role === "customer_admin" && relationshipOrgDetail?.length) {
      url = `${BASE_URL}/customer/${getCustomerId()}/candidate/agency_candidates/total_count`
    } else if (isTFWPool && org_id){
      url = `${BASE_URL}/customer/${getCustomerId()}/candidate/total_count/super_customer/`
      query = query || '';
      query = `&organisation_id=${org_id}&${query}`
    }else if (org_id)
      url = `${BASE_URL}/customer/${getCustomerId()}/organisation/${org_id}/candidate/total_count`

    if (role === "customer_admin" && relationshipOrgDetail?.length) {
      return axios({
        method: 'POST',
        url: url,
        data: relationshipOrgDetail
      });
    } else {
      if (query) {
        return axios.get(
          `${url}?${payload}${query}`
        );
      } else {
        return axios.get(
          `${url}?${payload}`
        );
      }
    }
  },
  deleteCandidates: ({ candidate_id, customer_uid, payload }) => {
    return axios.delete(
      `${BASE_URL}/customer/${customer_uid}/candidate/${candidate_id}/`, { data: payload });
  },
  restoreCandidates: ({ candidate_id, payload, customer_uid }) => {
    return axios.put(
      `${BASE_URL}/customer/${customer_uid}/candidate/${candidate_id}/restore/`, payload);
  },
  deactivateCandidates: ({ candidate_id, payload, customer_uid }) => {
    return axios.put(
      `${BASE_URL}/customer/${customer_uid}/candidate/${candidate_id}/deactivate`, payload);
  },
  reactivateCandidates: ({ candidate_id, payload, customer_uid }) => {
    return axios.put(
      `${BASE_URL}/customer/${customer_uid}/candidate/${candidate_id}/reactivate`, payload);
  },
  getReasons: payload => {
    return axios.get(
      `${BASE_URL}/reasons/reason/`);
  },
  downloadDocumentFile: url => {
    const endUrl = `${BASE_URL}/${url}`;
    return axios({
      method: "GET",
      url: endUrl,
      responseType: "blob"
    });
  },
  getCandidateDocuments: (candidate_uid) => {
    let url = `${BASE_URL}/customer/${getCustomerId()}/candidate/${candidate_uid}/document`;
    return axios.get(url);
  },
  getCandidateDetail: ({ candidate_uid, customer_uid }) => {
    return axios.get(`${BASE_URL}/customer/${customer_uid}/candidate/id/${candidate_uid}/`);
  },
  getJobByCandidate: id =>
    axios.get(`${BASE_URL}/customer/${getCustomerId()}/candidate/${id}/applied/jobs`),
  updateJobApplication: (job_id, payload) =>
    axios.put(`${BASE_URL}/customer/${getCustomerId()}/job/${job_id}/application/`, payload),
  candidateDetails: userId =>
    axios.get(`${BASE_URL}/customer/${getCustomerId()}/candidate/?user_id=${userId}`),
  getAllJobs: (role) => {
    let url;
    if (role === "system_admin") {
      url = `${BASE_URL}/job/summary?include_marketplace=false`
    } else {
      url = `${BASE_URL}/customer/${getCustomerId()}/job/summary?include_marketplace=false`
    }
    return axios.get(`${url}`);
  },
  startImpersonation: payload => {
    return axios.post(`${BASE_URL}/impersonation/start`, payload)
  },
  stopImpersonation: () => {
    return axios.post(`${BASE_URL}/impersonation/stop`)
  },
  getUser: (query) => {
    return axios.get(`${BASE_URL}/users/me?${query}`)
  },
  updateUser: (payload) => {
    return axios.put(`${BASE_URL}/users/me`, payload)
  },
  fetchCandidateList: ({ skip, limit, query }) => {
    return axios.get(`${BASE_URL}/customer/${getCustomerId()}/candidate/?skip=${skip}&limit=${limit}&order_by=-created_on&&doh_candidate_status___customer_id__in__and=null&${query}`)
  },
  'getJobByIdSer': ({ customer_uid, job_id }, org_id, isCandidateSupplier) => {
    let url
    if (isCandidateSupplier) {
      url = `${BASE_URL}/customer/${customer_uid}/job/id/${job_id}/?org_id=${org_id}`
    }
    else {
      url = `${BASE_URL}/customer/${customer_uid}/job/id/${job_id}/`
    }
    return axios.get(`${url}`);
  },
  getJobTotalCount: (org_id, role, isCandidateSupplier) => {
    let url;
    if (role === "system_admin") {
      url = `${BASE_URL}/job/total_count`;
    }
    else if (org_id) {
      if (isCandidateSupplier) {
        url = `${BASE_URL}/customer/${getCustomerId()}/job/total_count?job_allocation___allocated_organisation_id__in=${org_id}`
      }
      else {
        url = `${BASE_URL}/customer/${getCustomerId()}/job/total_count?organisation_uid=${org_id}`;
      }
    } else {
      url = `${BASE_URL}/customer/${getCustomerId()}/job/total_count`;
    }
    return axios.get(`${url}`);
  },
  getScratchPadItems: (customer_id, user_id) => {
    return axios.get(
      `${BASE_URL}/${customer_id}/recruiter/${user_id}/scratchpad/items`
    )
  },
  postScratchPad: (customer_id, user_id, payload) => {
    return axios.post(
      `${BASE_URL}/${customer_id}/recruiter/${user_id}/scratchpad/item/`, payload
    )
  },
  putScratchPad: (customer_id, user_id, payload, id) => {
    return axios.put(
      `${BASE_URL}/${customer_id}/recruiter/${user_id}/scratchpad/item/${payload.id}`, payload
    )
  },
  deleteScratchpad: (customer_id, user_id, payload) => {
    return axios.delete(
      `${BASE_URL}/${customer_id}/recruiter/${user_id}/scratchpad/item/${payload.id}`, payload
    )
  },
  getAllCandidateJobStatus: async () => {
    return axios.get(`${BASE_URL}/status/?status_type=Candidate%20Job%20Status`)
  },
  getCandidateCountByStatusByJob: async (status_id, job_id) => {
    return axios.get(`${BASE_URL}/jobcandidate/match/count/?status_id=${status_id}&job_id=${job_id}`)
  },
  getCandidateCountByStatus: async (status) => {
    const candidateJobStatus = await axios.get(`${BASE_URL}/status/?status_type=Candidate%20Job%20Status`)
    const result = candidateJobStatus?.data?.find((item) => item.status.toUpperCase() === status)
    return result
      ? axios.get(
        `${BASE_URL}/jobcandidate/match/count/?status_id=${result.status_id}`
      )
      : { data: 0 }
  },
  getCustomerIDByCandidateID: candidate_uid => axios.get(`${BASE_URL}/customer/candidate/${candidate_uid}`),
  getCustomerIDByJobID: job_id => axios.get(`${BASE_URL}/customer/job/${job_id}`),
  getAllOrganisation: () => axios.get(`${BASE_URL}/organisation/`),
  uploadUserPhoto: (query, payload) => axios.post(`${BASE_URL}/user_documents/user_document?${query}`, payload),
  deleteUserPhoto: ({ user_id, user_document_id }) => axios.delete(`${BASE_URL}/user_documents/user_document_id/${user_document_id}?user_id=${user_id}`),
  getUsersUploadedDocument: (user_id) => axios.get(`${BASE_URL}/user_documents/get_by_user_id/${user_id}`),
  getCustomerVariant: (customer_id) => axios.get(`${BASE_URL}/customer_application_configuration/?customer_id=${customer_id}`),
  updateCustomerVariant: (customer_id, payload) => axios.put(`${BASE_URL}/customer_application_configuration/?customer_id=${customer_id}`, payload),
  // getDocumentTypeForCustomerAdmin: () => axios.get(`${BASE_URL}/documents/types?order_by=document_type&entity_id=3`),
  createJobDocument: (job_id, customer_id, payload) => axios.post(`${BASE_URL}/job/${job_id}/document?customer_id=${customer_id}`, payload),
  getJobUploadedDocument: (job_id) => axios.get(`${BASE_URL}/job/${job_id}/document`),
  deleteJobDocument: (job_id, job_document_id) => axios.delete(`${BASE_URL}/job/${job_id}/document/${job_document_id}/`),
  updateJobDocument: (job_id, job_document_id, payload) => axios.put(`${BASE_URL}/job/${job_id}/document/${job_document_id}`, `comments=${payload}`),
  getCustomer_Details: (customer_id) => axios.get(`${BASE_URL}/customer/${customer_id}`),
  createOffer: (job_id, candidate_uid, payload) => axios.post(`${BASE_URL}/offer/?candidate_uid=${candidate_uid}&job_id=${job_id}`, payload),
  createOnboard: (payload) => axios.post(`${BASE_URL}/onboard/`, payload),
  getCustomerAdvertisement: (customer_id) => axios.get(`${BASE_URL}/customer_application_configuration/get_advertisement_config?customer_id=${customer_id}`),
  getOrgDetails: (organisation_id) => axios.get(`${BASE_URL}/organisation/id/${organisation_id}`),
  getCommentsfor_recruiter: ({ queryString }) => axios.get(`${BASE_URL}/comments/?${queryString}&entity_id=1`),
  getCommentsfor_candidateInterviewer: ({ queryString }) => axios.get(`${BASE_URL}/comments/?${queryString}&entity_id=3`),
  getCommentsfor_jobapproval: () => axios.get(`${BASE_URL}/comments/?entity_id=4`),
  getOrgDocumentsbytitleId: (organisation_id, documenttitle_id) => axios.get(`${BASE_URL}/document_library/get_document_library_title_id?organisation_id=${organisation_id}&documenttitle_id=${documenttitle_id}`),
  createjobRecord: (job_id, customer_id, organisation_id, organisation_document_id) => axios.post(`${BASE_URL}/job/${job_id}/documentinsert_job_record?customer_id=${customer_id}&organisation_id=${organisation_id}&organisation_document_id=${organisation_document_id}`),
  deletejobrecord: (job_id, job_document_id) => axios.delete(`${BASE_URL}/job/${job_id}/document/${job_document_id}/record`),
  createCustomerOrgRelationship: ({ payload, access_token }) => {
    return axios.post(`${BASE_URL}/customer_organisation_relationships/`, payload, {
      headers: {
        Authorization: 'Bearer ' + access_token,
      }
    })
  }
};

export default customer;
