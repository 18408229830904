import axios from "axios";
import { BASE_URL } from "@/service_urls";

const course = {
    createCourse: (payload) => axios.post(`${BASE_URL}/training_courses`, payload),
    createCourseModOpt: (payload) => axios.post(`${BASE_URL}/training_courses/combined_record`, payload),
    createCourseModule: (payload) => axios.post(`${BASE_URL}/training_course_modules/`, payload),
    createCourseOption: (payload) => axios.post(`${BASE_URL}/training_course_options/`, payload),
    createCourseSemester: (payload) => axios.post(`${BASE_URL}/course_semesters/`, payload),
    getCourses: ({ queryString }) => axios.get(`${BASE_URL}/training_courses/?${queryString}`),
    getTrainingTypes: () => axios.get(`${BASE_URL}/training_types/`),
    getCourseById: (training_course_id) => axios.get(`${BASE_URL}/training_courses/${training_course_id}`),
    updateCourse: ({ payload, training_course_id }) => axios.put(`${BASE_URL}/training_courses/${training_course_id}`, payload),
    updateCourseOption: ({ payload, training_course_opt_id }) => axios.put(`${BASE_URL}/training_course_options/${training_course_opt_id}`, payload),
    updateCourseModule: ({ payload, training_course_mod_id }) => axios.put(`${BASE_URL}/training_course_modules/${training_course_mod_id}`, payload),
    updateCourseSemester: ({ payload, course_semester_id }) => axios.put(`${BASE_URL}/course_semesters/${course_semester_id}`, payload),
    getCoursesCombined: ({ queryString }) => axios.get(`${BASE_URL}/training_courses/combined_training_courses?${queryString}`),
    deleteCourseOption: (training_course_opt_id) => axios.delete(`${BASE_URL}/training_course_options/${training_course_opt_id}`),
    deleteCourseModule: (training_course_mod_id) => axios.delete(`${BASE_URL}/training_course_modules/${training_course_mod_id}`),
    createCourseTemplate: ({ training_course_id, payload }) => axios.post(`${BASE_URL}/course_template/${training_course_id}/create_course_template`, payload),
    getCourseTemplate: (training_course_id) => axios.get(`${BASE_URL}/course_template/training_course/${training_course_id}/template_fields`),
    createInterestedCourseCandidate: (payload) => axios.post(`${BASE_URL}/course_interested_candidates/`,payload),
    getDepartments:() => axios.get(`${BASE_URL}/department/`),
    getOrgDepartments:(query) => axios.get(`${BASE_URL}/organisation_departments/?${query}`),
    getISCEDLevels:()=> axios.get(`${BASE_URL}/isced_levels/`)
}

export default course