import axios from "axios";
import { BASE_URL } from "@/service_urls";
import {
  getCustomerId,
  getLocalOrgDetail,
  CONSTANT as CONST,
} from "@/helpers/helper";
import router from "@/router/index";
import m from "moment";

const jobBoard = {
  getJobBoard: ({ customer_id, skip, limit, page, role, query, candidate_id, organisation_id, isCandidateSupplierFromAccessToken }) => {
    let url;
    let customURL;
    query = query ? `&${query}` : "";
    let jobQuery = "";
    if (!router?.currentRoute.fullPath.includes("public/job-board")) {
      jobQuery = "&is_own_job__in=null,true"
    }
    if (getLocalOrgDetail()?.customer_type_id === CONST.CUSTOMER_TYPES.educational_institute) {
      customer_id = CONST.CUSTOMERS.super_customer
      organisation_id = CONST.ORGANISATIONS.super_customer
      let params = new URLSearchParams(query);
      if (params.has('organisation_uid')) {
        params.set('organisation_uid', CONST.ORGANISATIONS.super_customer);
      }
      query = params.toString();
    }
    if (page === "jobBoard" || page === "publicJobBoard") {
      const { params: { jobStatus } } = router?.currentRoute;
      if (
        customer_id &&
        [
          "system_recruiter",
          "customer_admin",
          "customer_recruiter"
        ].includes(role)
      ) {
        // call for 3rd party agencies (List only allocated Jobs from Main Agency)
        if (isCandidateSupplierFromAccessToken) {
          url = `${BASE_URL}/customer/${customer_id}/job/cards?skip=0&limit=200&order_by=expiry_review_date&job_allocation___allocated_organisation_id__in=${organisation_id}&${query}`
        } else {
          customURL = router.currentRoute.name == "JobListNew"
            ? `${BASE_URL}/customer/${customer_id}/job/cards?`
            : `${BASE_URL}/customer/${customer_id}/job/summary?`;
          // Active Jobs //Inactive Jobs
          if (jobStatus == "active") customURL += 'status_id__in=27,28,29&'
          else if (jobStatus == "inactive") customURL += 'status_id__in=30&'
          else if (jobStatus == "archive") customURL += 'status_id__in=31&'

          if (organisation_id) customURL += `organisation_uid=${organisation_id}&`;
          url = `${customURL}skip=${skip}&limit=${limit}&order_by=-coalesce__last_modified_on__created_on${query}${jobQuery}`;
        }
      } else if (role === "system_admin") {
        customURL = router.currentRoute.name == "JobListNew"
          ? `${BASE_URL}/job/cards?`
          : `${BASE_URL}/job/summary?`;
        // Active Jobs //Inactive Jobs
        if (jobStatus == "active") query += '&status_id__in=27,28,29'
        else if (jobStatus == "inactive") query += '&status_id__in=30'
        else if (jobStatus == "archive") query += '&status_id__in=31'

        url = `${customURL}skip=${skip}&limit=${limit}&order_by=-coalesce__last_modified_on__created_on${query}${jobQuery}`;
      } else if (["customer_candidate", "system_candidate"].includes(role)) {
        query += "&private=false"
        url = `${BASE_URL}/customer/${customer_id}/job/candidate/${candidate_id}/summary?skip=${skip}&limit=${limit}&date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}&order_by=-coalesce__last_modified_on__created_on${query}${jobQuery}`;
      } else {
        const { params: { cus_id, org_id } } = router?.currentRoute;
        let customer_uid = cus_id;
        query += org_id ? `&organisation_uid=${org_id}&private=false` : "&private=false";
        url = `${BASE_URL}/job-board/public/summary?skip=${skip}&limit=${limit}&date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}&order_by=-coalesce__last_modified_on__created_on&customer_uid=${customer_uid}${query}${jobQuery}`;
      }
    } else if (page === "jobList") {
      url = `${BASE_URL}/customer/${customer_id}/job/?include_marketplace=false&skip=${skip}&limit=${limit}&order_by=-coalesce__last_modified_on__created_on${query}`;
    }
    return axios({
      method: 'GET',
      url: url
    });
  },
  fetchPublicAdvertDescForJobId: ({ job_id }) => {
    let url = `${BASE_URL}/job-board/public/advert_description?job_id=${job_id}`;
    return axios({
      method: 'GET',
      url: url
    });
  },
  fetchJobBenefitsAdvertDescByJobId: ({ customer_id, job_id }) => axios.get(`${BASE_URL}/customer/${customer_id}/job/${job_id}/benefits/details/advertdesc`),
  getJobDetail: ({ customer_id, job_id, path, role, candidate_id }) => {
    let url;
    if (path && (path.includes("/public/job-board-detail") || path.includes("/tfw/job-board-detail"))) {
      if (
        customer_id &&
        [
          "system_admin",
          "system_recruiter",
          "customer_admin",
          "customer_recruiter"
        ].includes(role)
      ) {
        url = `${BASE_URL}/customer/${customer_id}/job/id/${job_id}/`;
      } else {
        url = `${BASE_URL}/job-board/public?job_id=${job_id}`;
      }
    } else {
      url = candidate_id ? `${BASE_URL}/customer/${customer_id}/job/id/${job_id}/?candidate_uid=${candidate_id}` :
        `${BASE_URL}/customer/${customer_id}/job/id/${job_id}/`
    }
    return axios.get(url);
  },
  fetchApplicationStatus: ({ candidate_uid, job_id }) => {
    let url = `${BASE_URL}/job/application/${job_id}/candidate/${candidate_uid}`;
    return axios.get(url);
  },
  applyJob: (candidate_id, job_id, payload) => {
    let url = `${BASE_URL}/job/application/${job_id}/candidate/${candidate_id}`;
    return axios.post(url, payload);
  },
  withdrawJob: (candidate_id, job_id) => {
    let url = `${BASE_URL}/job/application/${job_id}/candidate/${candidate_id}`;
    return axios.put(url);
  },
  getJobsCount: ({ query, role }) => {
    let url = ""
    let subQuery = ""
    if (!router?.currentRoute.fullPath.includes("public/job-board")) {
      subQuery = "&is_own_job__in=null,true"
    }
    if (["system_admin"].includes(role)) {
      const { params: { jobStatus } } = router?.currentRoute;
      // Active Jobs //Inactive Jobs
      if (jobStatus == "active") query += '&status_id__in=27,28,29'
      else if (jobStatus == "inactive") query += '&status_id__in=30'
      else if (jobStatus == "archive") query += '&status_id__in=31'

      url = `${BASE_URL}/job/total_count?${query}${subQuery}`;
    } else {
      const { params: { cus_id, org_id } } = router?.currentRoute;
      let customer_uid = cus_id;
      query += org_id ? `&organisation_uid=${org_id}` : "";
      url = `${BASE_URL}/job-board/public/total_count?customer_uid=${customer_uid}&date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}&${query}${subQuery}&private=false`;
    }
    return axios.get(url);
  },
  getJobsCountFacility: (query, customer_id, org_id, isCandidateSupplier) => {
    const { params: { jobStatus } } = router?.currentRoute;
    let subQuery = ""
    if (!router?.currentRoute.fullPath.includes("public/job-board")) {
      subQuery = "&is_own_job__in=null,true"
    }
    // Active Jobs //Inactive Jobs
    if (jobStatus == "active") query += '&status_id__in=27,28,29'
    else if (jobStatus == "inactive") query += '&status_id__in=30'
    else if (jobStatus == "archive") query += '&status_id__in=31'

    let url = `${BASE_URL}/customer/${customer_id}/job/total_count`;
    let isPartnerAgency = false;
    if (isCandidateSupplier) {
      isPartnerAgency = true;
      url = `${BASE_URL}/customer/${customer_id}/job/total_count`;
      query += `&job_allocation___allocated_organisation_id__in=${org_id}`
    } else isPartnerAgency = false;
    if (org_id && !isPartnerAgency) {
      query = query + `&organisation_uid=${org_id}`;
    }
    if (!isPartnerAgency) {
      if (query) {
        query = query + `&${subQuery}`
      } else {
        query = `${subQuery}`
      }
    }
    return axios.get(`${url}?${query}`);
  },
  updateJob: (payload, id, customer_id) =>
    axios.put(
      `${BASE_URL}/customer/${customer_id}/job/organisation/${id}/`,
      payload
    ),
  addPreferredJob: ({ customer_id, candidate_id, payload }) => axios.post(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/preferred_job/`, payload),
  deletePreferredJob: ({ customer_id, candidate_id, preferred_id }) => axios.delete(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/preferred_job/candidate_preferred_job_id/${preferred_id}`),
  getPreferredJobCount: ({ customer_id, candidate_id, query }) => axios.get(`${BASE_URL}/customer/${customer_id}/candidate/${candidate_id}/preferred_job/count?${query}`),
  candidateWithdrawJobsCount: ({ candidate_id, query }) => axios.get(`${BASE_URL}/job/application/total_count?candidate_uid=${candidate_id}&is_withdrawn=true&is_current=true&${query}`),
  candidateAppliedJobsCount: ({ candidate_id, query }) => axios.get(`${BASE_URL}/job/application/total_count?candidate_uid=${candidate_id}&is_withdrawn=false&is_current=true&${query}`),
  getMatchedJobCount: ({ candidate_id, query }) => axios.get(`${BASE_URL}/jobcandidate/match/candidate/${candidate_id}/count?${query}`),
  getJobsCountCandidate: ({ customer_id, candidate_id, query }) => {
    let subQuery = ""
    if (!router?.currentRoute.fullPath.includes("public/job-board")) {
      subQuery = "&is_own_job__in=null,true"
    }
    if (query) {
      query = query + `&${subQuery}`
    } else {
      query = `${subQuery}`
    }
    query += '&private=false'
    return axios.get(`${BASE_URL}/customer/${customer_id}/job/candidate/${candidate_id}/total_count?date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}&${query}`)
  },
  fetchAllJobsFilterList: ({ query }) => axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/job_id/job_title?status_id__in=28,29${query ? '&' + query : ''}`),
  getAllJobsForAgency: ({ customer_id, skip, limit, page, query }, payload) => {
    let url;
    query = query ? `&${query}` : "";
    if (page === "jobBoard" || page === "publicJobBoard") {
      const { params: { jobStatus } } = router?.currentRoute;
      // Active Jobs //Inactive Jobs
      if (jobStatus == "active") query += '&status_id__in=27,28,29'
      else if (jobStatus == "inactive") query += '&status_id__in=30'
      else if (jobStatus == "archive") query += '&status_id__in=31'
      url = `${BASE_URL}/customer/${customer_id}/job/agency_jobs/summary?skip=${skip}&limit=${limit}&order_by=-coalesce__last_modified_on__created_on${query}`;
    } else if (page === "jobList") {
      url = `${BASE_URL}/customer/${customer_id}/job/agency_jobs/summary?include_marketplace=false&skip=${skip}&limit=${limit}&order_by=-coalesce__last_modified_on__created_on${query}`;
    }
    return axios.post(`${url}`, payload);
  },
  getAllJobsCountForAgency: (query, customer_id, payload) => {
    const { params: { jobStatus } } = router?.currentRoute;
    // Active Jobs //Inactive Jobs
    if (jobStatus == "active") query += '&status_id__in=27,28,29'
    else if (jobStatus == "inactive") query += '&status_id__in=30'
    else if (jobStatus == "archive") query += '&status_id__in=31'
    let url = `${BASE_URL}/customer/${customer_id}/job/agency_jobs/total_count`;
    return axios.post(`${url}?${query}`, payload);
  },
  getJobAdvertisements: ({ queryString }) => axios.get(`${BASE_URL}/job_advertisement/all_job_adverts?${queryString}`),
  uploadAdvert: ({ queryString, document }) => axios.post(`${BASE_URL}/job_advertisement/create_new_job_advertisement?${queryString}`, document),
  updateAdvert: ({ queryString, document, fileName, job_advertisement_id }) => {
    if (fileName)
      return axios.put(`${BASE_URL}/job_advertisement/update/job_advertisements/${job_advertisement_id}?${queryString}`, document)
    else
      return axios.put(`${BASE_URL}/job_advertisement/update/job_advertisements/${job_advertisement_id}?${queryString}`)
  },
  deleteJobAdvert: (job_advertisement_id) => axios.delete(`${BASE_URL}/job_advertisement/delete/job_advertisements/${job_advertisement_id}`),
  fetchAllJobsByShortlistProcess: ({ candidate_id, query }) => axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/candidate/${candidate_id}/job_title?${query}`),
  getCustomerOrganisation: ({ customer_id, query }) => axios.get(`${BASE_URL}/customer/${customer_id}/organisation/?${query}`),
};

export default jobBoard;
